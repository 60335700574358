<template>
	<div>
		<!-- DASHBOARD -->
		<v-row no-gutters class="my-md-12">
			<v-col cols="12" align="center"><span class="text-h4">{{ $t("Home.Hello") }}, {{ firstName }}</span></v-col>
			<v-col cols="12" align="center"><span class="text-h6 font-weight-light">{{ customerName }}</span></v-col>
		</v-row>
		<v-row justify="center">
			<v-col cols="8" md="auto" v-for="item in dashboardInfo" :key="item.itemId" align="center">
				<v-card hover rounded :to="{ name: item.itemName }" width="200">
					<v-card-title>
						{{ $t('Home.' + item.itemLabel) }}
					</v-card-title>
					<v-card-text class="text-h4 text-center info--text">{{ item.itemCount }}</v-card-text>
				</v-card>
			</v-col>
		</v-row>

		<v-footer fixed class="transparent">
			<v-row justify="center">
				<v-col cols="4" md="auto" v-for="(shortCut, index) in shortCuts" :key="index">
					<v-btn text :to="shortCut.routeName">
						{{ shortCut.label }}
					</v-btn>
				</v-col>
			</v-row>
		</v-footer>
	</div>
</template>
<script>
import store from "@/store";
import { mapGetters } from "vuex";
export default {
	name: "Home",
	mounted() {
		const that = this;
		store.dispatch("session/fetchDashboardInfo").then(() => {
			that.mounted = true;
		});
	},
	data() {
		return {
			mounted: false,
		};
	},
	computed: {
		...mapGetters({
			firstName: "session/firstName",
			customerName: "session/customerName",
			dashboardInfo: "session/dashboardInfo",
		}),
		shortCuts() {
			const kmp = [
				{
					label: this.$t("Home.My_profile"),
					routeName: { name: "UserProfile" },
				},
				{
					label: this.$t("Home.My_invoices"),
					routeName: { name: "SalesInvoices" },
				},
				{
					label: this.$t("Home.Webshop"),
					routeName: { name: "ProductCatalog" },
				},
			];
			return kmp;
		},
	},
};
</script>
