<template>
	<!-- VERTICAL -->
	<div>
		<v-row no-gutters v-if="vertical">
			<v-col cols="12">
				<v-list nav flat>
					<div v-for="navItem in navItems" :key="navItem.id">
						<v-list-item v-if="!navItem.children" :key="navItem.id" router :to="{ name: navItem.routeName }">
							<v-list-item-title v-text="navItem.text" />
						</v-list-item>
						<v-list-group v-else no-action>
							<template v-slot:activator>
								<v-list-item-title v-text="navItem.text" />
							</template>
							<v-list-item dense v-for="_navItem in navItem.children" :key="_navItem.id" router
								:to="{ name: _navItem.routeName }">
								<v-list-item-title v-text="_navItem.label" />
							</v-list-item>
						</v-list-group>
					</div>
				</v-list></v-col></v-row>
		<!-- HORIZONTAL -->
		<v-row v-else>
			<v-col cols="auto" v-for="navItem in navItems" :key="navItem.id">
				<v-btn v-if="!navItem.children" :to="{ name: navItem.routeName }" exact plain height="64">
					{{ navItem.text }}
				</v-btn>
				<v-menu v-else bottom offset-y open-on-hover open-delay="250">
					<template v-slot:activator="{ on, attrs }">
						<v-btn v-bind="attrs" v-on="on" plain height="64">
							{{ navItem.text }}
							<v-icon right>mdi-chevron-down</v-icon>
						</v-btn>
					</template>
					<v-list outlined tile>
						<v-list-item v-for="_navItem in navItem.children" :key="_navItem.id" router
							:to="{ name: _navItem.routeName }" exact active-class="primary--text">
							{{ _navItem.label }}
						</v-list-item>
					</v-list>
				</v-menu>
			</v-col>
		</v-row>
	</div>
</template>

<script>
export default {
	name: "SmartNavBar",
	props: {
		vertical: Boolean,
	},
	computed: {
		navItems() {
			const kmp = [
				{
					id: 1,
					text: this.$t("SmartNavBar.My_account"),
					icon: "mdi-wallet-outline",
					children: [
						{
							id: 100,
							label: this.$t("SmartNavBar.My_quotes"),
							routeName: "SalesQuotes",
						},
						{
							id: 101,
							label: this.$t("SmartNavBar.My_orders"),
							routeName: "SalesOrders",
						},

						{
							id: 102,
							label: this.$t("SmartNavBar.My_shipments"),
							routeName: "Shipments",
						},
						{
							id: 103,
							label: this.$t("SmartNavBar.My_consignments"),
							routeName: "Consignments",
						},
						{
							id: 104,
							label: this.$t("SmartNavBar.My_repairs"),
							routeName: "Repairs",
						},
						{
							id: 105,
							label: this.$t("SmartNavBar.My_invoices"),
							routeName: "SalesInvoices",
						},
					],
				},
				{
					id: 2,
					text: this.$t("SmartNavBar.My_products"),
					icon: "mdi-book-open-blank-variant",
					children: [
						{
							id: 0,
							label: this.$t("SmartNavBar.My_purchases"),
							routeName: "SalesHistory",
						},
						{
							id: 1,
							label: this.$t("SmartNavBar.My_price_agreements"),
							routeName: "PriceAgreements",
						},
					],
				},
				{
					id: 3,
					text: this.$t("SmartNavBar.Webshop"),
					icon: "mdi-store-outline",
					routeName: "ProductCatalog",
				},
			];
			return kmp;
		},
	},
};
</script>