import caas from "@/services/caas";

// state
const state = {
    cart: [],
    addressList: [],
    deliveryAddressId: 0,
    invoiceAddressId: 0,
    remark: "",
    reference: "",
    ui: {
        locale: ""
    }
};

// actions
const actions = {
    fetchShoppingCart: (context) => {
        return caas
            .rpc("getShoppingCart", {})
            .then((response) => {
                context.commit(
                    "setShoppingCart",
                    response.data.success.data.data
                );
            })
            .catch((error) => {
                console.log(error);
            });
    },
    fetchAddressList: (context) => {
        return caas.rpc("getPortalUserAddressList", {}).then((response) => {
            const _addressList = response.data.success.data.addressList
            context.commit(
                "setAddressList", _addressList
            );
            const x = _addressList.filter(address => address.addressType == "delivery")
            if (x && x.length == 1) {
                context.commit(
                    "setDeliveryAddressId", x[0].addressId
                )
            }
            const y = _addressList.filter(address => address.addressType == "invoice")
            if (y && y.length == 1) {
                context.commit(
                    "setInvoiceAddressId", y[0].addressId
                )
            }


        });
    },
    convertShoppingCart: (context) => {
        let params = {
            cartId: context.state.cart.cartId,
            remark: context.state.remark,
            customerReference: context.state.reference,
            deliveryAddressId: context.state.deliveryAddressId,
            invoiceAddressId: context.state.invoiceAddressId,
        };
        return caas
            .rpc("convertShoppingCartToOrder", params)
            .then((response) => {
                context.commit("resetShoppingCart");
                return response;
            })
            .catch((error) => {
                throw error;
            });
    },
    deleteCartItem: (context, pCartItem) => {
        let params = {
            cartItemId: pCartItem.cartItemId,
        };
        return caas.rpc("deleteShoppingCartItem", params).then((response) => {
            context.commit("setShoppingCart", response.data.success.data.data);
        });
    },
    upsertCartItem: (context, cartItemUpd) => {
        return caas
            .rpc("upsertShoppingCartItem", cartItemUpd)
            .then((response) => {
                context.commit(
                    "setShoppingCart",
                    response.data.success.data.data
                );
            });
    },
    setRemark: (context, pRemark) => {
        context.commit("setRemark", pRemark);
    },
    setReference: (context, pReference) => {
        context.commit("setReference", pReference);
    },
};

// mutations
const mutations = {
    setLocale: (state, _locale) => {
        state.ui.locale = _locale
    },
    setShoppingCart: (state, payload) => {
        if (!payload) return;
        state.cart = payload.cart[0];
    },
    resetShoppingCart: (state) => {
        state.cart = [];
        state.cartItems = [];
        state.deliveryAddressId = 0;
        state.invoiceAddressId = 0;
        state.remark = "";
        state.reference = "";
    },
    setAddressList: (state, payload) => {
        state.addressList = payload;
    },
    setDeliveryAddressId: (state, pId) => {
        if (state.deliveryAddressId === pId) {
            state.deliveryAddressId = 0;
        } else {
            state.deliveryAddressId = pId;
        }
    },
    setInvoiceAddressId: (state, pId) => {
        if (state.invoiceAddressId === pId) {
            state.invoiceAddressId = 0;
        } else {
            state.invoiceAddressId = pId;
        }
    },
    setRemark: (state, pRemark) => {
        state.remark = pRemark;
    },
    setReference: (state, pReference) => {
        state.reference = pReference;
    },
};

// getters
const getters = {
    cart: (state) => {
        return state.cart;
    },
    cartItems: (state) => {
        // sort by createdOn
        if (state.cart.cartItems) {
            state.cart.cartItems.sort(function (a, b) {
                return new Date(a.createdOn) - new Date(b.createdOn);
            });
        }
        return state.cart.cartItems;
    },
    cartItem: (state) => (_productCode) => {
        let result = {};
        if (state.cart.cartItems) {
            result = state.cart.cartItems.find(
                (_cartItem) => _cartItem.productCode === _productCode
            );
        }
        //console.log("RESULT", result);
        return result;
    },
    cartItemCount: (state) => {
        if (state.cart.cartItems) {
            return state.cart.cartItems.length;
        } else {
            return 0;
        }
    },
    deliveryAddressId: (state) => {
        return state.deliveryAddressId;
    },
    invoiceAddressId: (state) => {
        return state.invoiceAddressId;
    },
    invoiceAddressList: (state) => {
        return state.addressList.filter((x) => {
            return x.addressType === "invoice";
        });
    },
    deliveryAddressList: (state) => {
        return state.addressList.filter((x) => {
            return x.addressType === "delivery";
        });
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
