import Vue from "vue";
import Vuex from "vuex";
import session from "@/store/modules/session";
import catalog from "@/store/modules/catalog";
import shoppingCart from "@/store/modules/shoppingCart";

Vue.use(Vuex);
const store = new Vuex.Store({
    modules: {
        session,
        catalog,
        shoppingCart,
    },
});
export default store;
