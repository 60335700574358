import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { signInWithEmailAndPassword } from "firebase/auth";
import { signOut } from "firebase/auth";
import { sendPasswordResetEmail } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
// init app
const firebaseConfig = {
    apiKey: "AIzaSyB69ce1YNxXYJwqcyHfh0gOCSBCyOKZ0cA",
    authDomain: "ledtechno-56834.firebaseapp.com",
    projectId: "ledtechno-56834",
    storageBucket: "ledtechno-56834.appspot.com",
    messagingSenderId: "491389758746",
    appId: "1:491389758746:web:8f9845f51af963a6ed90a3",
};
const app = initializeApp(firebaseConfig);
const fireAuth = getAuth();
const db = getFirestore(app);
const functions = getFunctions(app, "europe-west1");
export {
    fireAuth,
    signInWithEmailAndPassword,
    signOut,
    sendPasswordResetEmail,
    db,
    functions
};
