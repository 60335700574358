import caas from "@/services/caas";

// STATE
const state = {
    firebaseUser: {},
    webSessionId: null,
    userInfo: {
        profileCode: "",
        userId: 0,
        userFirstName: "",
        userLastName: "",
        customerId: 0,
        customerName: "",
    },
    dashboardInfo: [],
    ui: {
        contentHeight: null,
        locale: ""
    },
    bottomSheet: {
        message: "",
        isVisible: false,
        color: "info",
        success: null,
    },
};
// ACTIONS
const actions = {
    startWebSession: async (context) => {
        try {
            const response = await caas.rpc("startWebSession", {
                userId: context.state.firebaseUser.uid,
                tenantId: Number(process.env.VUE_APP_TENANT_ID),
            });
            if (response.data.success) {
                context.commit("setWebsessionId", response.data.success.webSessionId);
                context.commit("setUserInfo", response.data.success);
                context.dispatch('shoppingCart/fetchShoppingCart', {}, { root: true })
            }
        } catch (err) {
            console.error(err)
        }
    },
    endWebSession: async () => {
        const response = await caas.rpc("endWebSession");
        location.reload();
        return response;
    },
    fetchDashboardInfo: async (context) => {
        const response = await caas.rpc("getPortalUserDashboardInfo", {});
        context.commit(
            "setDashboardInfo",
            response.data.success.data.dashboardInfo
        );
    },
    updateUI: (context, payload) => {
        context.commit("setContentHeight", payload.contentHeight);
    },
    showBottomMessage: (context, message) => {
        var timeout = 15000;
        context.commit("setBottomMessage", message);
        context.commit("setBottomSuccess", null);
        context.commit("showBottomMessage", true);
        window.setTimeout(function () {
            context.commit("showBottomMessage", false);
        }, timeout);
    },
    showSuccess: (context) => {
        var timeout = 1000;
        context.commit("setBottomMessage", "");
        context.commit("setBottomSuccess", true);
        context.commit("showBottomMessage", true);
        window.setTimeout(function () {
            context.commit("showBottomMessage", false);
        }, timeout);
    },
};
// MUTATIONS
const mutations = {
    setLocale: (state, _locale) => {
        state.ui.locale = _locale
    },
    setBottomMessage: (state, pMessage) => {
        state.bottomSheet.message = pMessage;
    },
    setBottomSuccess: (state, pSuccess) => {
        state.bottomSheet.success = pSuccess;
    },
    showBottomMessage: (state, pShow) => {
        state.bottomSheet.isVisible = pShow;
    },
    setDashboardInfo: (state, payload) => {
        state.dashboardInfo = payload;
    },
    setWebsessionId: (state, payload) => {
        state.webSessionId = payload;
    },
    setContentHeight: (state, pContentHeight) => {
        state.ui.contentHeight = pContentHeight;
    },
    setFirebaseUser: (state, payload) => {
        state.firebaseUser = payload;
    },
    setUserInfo: (state, payload) => {
        state.userInfo.profileCode = payload.profileCode;
        state.userInfo.userId = payload.userId;
        state.userInfo.userFirstName = payload.userFirstName;
        state.userInfo.userLastName = payload.userLastName;
        state.userInfo.customerId = payload.customerId;
        state.userInfo.customerName = payload.customerName;
    },
};
// GETTERS
const getters = {
    bottomSheet: (state) => {
        return state.bottomSheet;
    },
    contentHeight: (state) => {
        return state.ui.contentHeight;
    },
    sessionActive: (state) => {
        if (state && state.firebaseUser && state.webSessionId) {
            return true;
        }
        return false;
    },
    webSessionId: (state) => {
        return state.webSessionId;
    },
    firebaseUserId: (state) => {
        if (state.firebaseUser) {
            return state.firebaseUser.uid;
        }
        return null;
    },
    firebaseUserEmail: (state) => {
        if (state.firebaseUser) {
            return state.firebaseUser.email;
        }
    },
    dashboardInfo: (state) => {
        return state.dashboardInfo;
    },
    firstName: (state) => {
        return state.userInfo.userFirstName;
    },
    lastName: (state) => {
        return state.userInfo.userLastName;
    },
    userName: (state) => {
        return state.userInfo.userFirstName + " " + state.userInfo.userLastName;
    },
    customerId: (state) => {
        return state.userInfo.customerId;
    },
    customerName: (state) => {
        return state.userInfo.customerName;
    },
    userId: (state) => {
        return state.userInfo.userId;
    },
    locale: (state) => {
        return state.ui.locale
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
