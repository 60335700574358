<style scoped>
	.bg {
		height: 50%;
	}
	.hero {
		position: fixed;
		top: 50%;
		right: 50%;
		width: 25%;
		transform: translate(-75%, -50%);
	}
	.centered {
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
	.card-cntnr {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}
	.card-title {
		flex: 0 0 auto;
		display: flex;
		flex-direction: column;
	}
	.card-subtitle {
		flex: 0 0 auto;
	}
	.card-text {
		flex: 1;
		display: flex;
		align-items: center;
	}
	.kmk {
		position: fixed;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	}
	.card-actions {
		align-self: flex-start;
	}
</style>
<template>
	<div class="bg primary lighten-4" v-if="!sessionActive">

		<v-img src="@/assets/logo.svg" class="hero" />
		<v-card class="card-cntnr centered" height="500px" width="400px" max-height="90vh" max-width="100vw">

			<v-card-title class="card-title">
				<span class="mt-3">{{ $t("Login.Customer_portal") }}</span>
			</v-card-title>

			<v-card-subtitle class="card-subtitle text-center">
				{{ subheader }}
			</v-card-subtitle>

			<v-card-text class="card-text">
				<v-stepper v-model="step" flat style="width:100%" class="kmk">
					<!-- E-MAIL -->
					<v-stepper-content step="1">
						<v-text-field v-model="userEmail" :label="$t('Login.E-mail')" autofocus outlined
							persistent-placeholder @keyup.native.enter="loginStep1" class="mt-1" />
						<v-btn block :loading="loading" color="primary" @click.stop="loginStep1">
							{{ $t("Login.Next") }}
						</v-btn>
					</v-stepper-content>
					<!-- PASSWORD -->
					<v-stepper-content step="2">
						<v-text-field v-model="userPwd" autofocus outlined persistent-placeholder class="mt-1"
							:type="showPassword ? 'text' : 'password'" :label="$t('Login.Password')" :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'
								" @click:append="showPassword = !showPassword" @keyup.native.enter="loginStep2" />
						<v-btn color="primary" block :loading="loading" @click="loginStep2">{{
							$t("Login.Sign_in") }}
						</v-btn>
					</v-stepper-content>
					<!-- CUSTOMER -->
					<v-stepper-content step="3">
						<v-autocomplete v-model="customerId" :label="$t('Login.Select_a_customer')" autofocus outlined
							persistent-placeholder @keyup.native.enter="loginStep3" class="mt-1"
							menu-props="bottom, offset-y" clearable :items="customerList" />
						<v-btn block :loading="loading" color="primary" @click="loginStep3" :disabled="!customerId">
							{{ $t("Login.Sign_in") }}
						</v-btn>
					</v-stepper-content>
				</v-stepper>
			</v-card-text>

			<v-card-actions v-if="step == 1">
				<v-spacer />
				<v-btn-toggle v-model="language" exclusive dense borderless color="primary" @change="onChangeLanguage">
					<v-btn x-small text value="nl"> NL </v-btn>
					<v-btn x-small text value="fr"> FR </v-btn>
					<v-btn x-small text value="en"> EN </v-btn>
				</v-btn-toggle>
				<v-spacer />
			</v-card-actions>
			<v-card-actions v-if="step == 2">
				<v-btn x-small plain @click="step = 1">
					{{ $t("Login.Previous_step") }}
				</v-btn>
				<v-spacer />
				<v-btn color="primary" x-small plain @click="resetPassword">{{ $t("Login.Forgot_your_password?")
					}}</v-btn>
			</v-card-actions>
		</v-card>
	</div>
</template>

<script>
import { fireAuth, signInWithEmailAndPassword } from "@/services/firebase";
import { mapGetters } from "vuex";
import caas from "@/services/caas";
export default {
	name: "Login",

	activated() {
		this.step = 1;
	},

	data: () => ({
		step: 1,
		userEmail: "",
		userPwd: "",
		showPassword: false,
		loading: false,
		tenantId: Number(process.env.VUE_APP_TENANT_ID),
		customerList: [],
		customerId: 0,
		language: ""
	}),

	computed: {
		...mapGetters({
			sessionActive: "session/sessionActive",
			firebaseUserId: "session/firebaseUserId",
		}),
		subheader() {
			switch (this.step) {
				case 1:
					return this.$t("Login.Sign_in_with_your_email_address");
				case 2:
					return this.$t("Login.Sign_in_as") + " " + this.userEmail;
				case 3:
					return this.$t("Login.Sign_in_as_customer");
				default:
					return "";
			}
		},
	},

	created() {
		this.language = this.$root.$i18n.locale
	},

	methods: {
		onChangeLanguage() {
			this.$root.$i18n.locale = this.language
		},
		loginStep1() {
			if (/.+@.+/.test(this.userEmail)) this.step = 2;
		},
		loginStep2() {
			signInWithEmailAndPassword(fireAuth, this.userEmail, this.userPwd)
				.then(() => {
					if (
						this.userEmail.match("@continuans") ||
						this.userEmail.match("@ledtechno")
					) {
						this.step = 3;
						this.loading = true;
						this.getCustomerList().then(() => {
							this.loading = false;
						});
					} else {
						this.loading = true;
						let that = this;
						window.setTimeout(function () {
							that.$router.push({ name: "Home" });
							that.loading = false;
						}, 2000);
					}
				})
				.catch((err) => {
					this.$store.dispatch("session/showBottomMessage", err);
				});
		},
		loginStep3() {
			if (!this.customerId > 0) {
				this.$store.dispatch("session/showBottomMessage", "Ongeldig klantnummer");
				return
			}
			let that = this;
			this.loading = true;
			this.setCustomerId()
				.then((response) => {
					if (response.data.success) {
						window.setTimeout(function () {
							that.$router.push({ name: "Home" });
							that.loading = false
						}, 2000);
					}
					if (response.data.error) {
						throw response.data.error;
					}
				})
				.catch((error) => {
					this.$store.dispatch("session/showBottomMessage", error);
				});
		},
		resetPassword() {
			this.$router.push({
				name: "ResetPassword",
				params: { mailTo: this.userEmail },
			});
		},
		getCustomerList() {
			let params = {
				tenantId: this.tenantId,
				userId: this.firebaseUserId,
			};
			return caas
				.rpc("getCustomerList", params)
				.then((response) => {
					if (response.data.success) {
						let arr1 = response.data.success.data.customerList;
						this.customerList = arr1.map((v) => ({
							text: v.customerName,
							value: v.customerId,
						}));
					}
					if (response.data.error) {
						throw response.data.error;
					}
				})
				.catch((error) => {
					this.$store.dispatch("session/showBottomMessage", error);
				});
		},
		setCustomerId() {
			let params = {
				tenantId: this.tenantId,
				userId: this.firebaseUserId,
				customerId: this.customerId,
			};
			return caas.rpc("setCustomerId", params);
		},
	},

	mounted() {
		this.$vuetify.goTo(0);
	},
};
</script>
