import caas from "@/services/caas";

// STATE
const state = {
    catalogList: [],
    categoryList: [],
    itemList: [],
    catalogItem: {},
    ui: {
        loading: false,
        searchText: "",
        currentCatalog: {},
        currentCategory: {},
        rowsToBatch: 50,
        rowNum: 1,
        loadMore: false,
        locale: ""
    },
};

// ACTIONS
const actions = {
    openQuery: (context) => {
        context.commit("setLoading", true);
        let params = {
            catalogId: state.ui.currentCatalog.catalogId,
            categoryId: state.ui.currentCategory.id || 0,
            searchText: state.ui.searchText,
            brandId: 0, // nog niet geimplementeerd
            languageCode: state.ui.locale,
            rowsToBatch: state.ui.rowsToBatch,
            rowNum: state.ui.rowNum,
        };
        return caas
            .rpc("getCatalogItems", params)
            .then((response) => {
                if (response.data.success) {
                    context.commit(
                        "pushItemList",
                        response.data.success.data.itemList
                    );
                    context.commit(
                        "setRowNum",
                        response.data.success.rowNum
                    );
                    context.commit(
                        "setLoadMore",
                        response.data.success.rowCnt ===
                        state.ui.rowsToBatch
                    );
                    context.commit("setLoading", false);
                }
                if (response.data.error) throw response.data.error;
            })
            .catch((error) => {
                context.dispatch("session/showBottomMessage", error);
                context.commit("setLoading", false);
            })
    },

    fetchCatalogItem: (context, pItemId) => {
        context.commit("setLoading", true);
        context.commit("setCatalogItem", null);
        let params = {
            itemId: pItemId,
            languageCode: context.state.ui.locale
        }
        return caas
            .rpc("getCatalogItem", params)
            .then((response) => {
                context.commit("setLoading", false);
                if (response.data.success) {
                    context.commit(
                        "setCatalogItem",
                        response.data.success.data.catalogItem[0]
                    );
                }
            })
            .catch((e) => {
                context.commit("setLoading", false);
                console.log(e)
            });
    },

    fetchCatalog: (context) => {

        if (context.state.ui.currentCatalog && context.state.ui.currentCatalog.catalogId) {
            return
        }

        let params = {};
        // catalog
        return caas
            .rpc("getCatalogList", params)
            .then((response) => {
                context.commit(
                    "SET_CATALOG_LIST",
                    response.data.success.data.catalogList
                );
                context.commit(
                    "SET_CURRENT_CATALOG",
                    response.data.success.data.catalogList[0]
                );
                //fetch categories of first catalog
                params = {
                    catalogId: response.data.success.data.catalogList[0].catalogId,
                    languageCode: context.state.ui.locale
                };
                return caas.rpc("getCategoriesOfCatalog", params)
                    .then((response) => {
                        context.commit(
                            "SET_CATEGORY_LIST",
                            response.data.success.data.categoryList
                        );
                    })
                    .catch((error) => {
                        console.log(error.message);
                    });
            })
            .catch((error) => {
                console.log(error.message);
            });
    },
};

// MUTATIONS
const mutations = {
    setLocale: (state, _locale) => {
        state.ui.locale = _locale
    },
    setCatalogItem: (state, payload) => {
        state.catalogItem = payload;
    },
    SET_CATALOG_LIST: (state, payload) => {
        state.catalogList = payload;
    },
    SET_CATEGORY_LIST: (state, payload) => {
        state.categoryList = payload;
    },
    SET_CURRENT_CATALOG: (state, payload) => {
        state.ui.currentCatalog = payload;
    },
    setCurrentCategory: (state, pCategory) => {
        state.ui.currentCategory = pCategory;
        // reset
        state.ui.rowNum = 1;
        state.ui.loadMore = false;
        state.itemList = [];
        state.catalogItem = {};
        state.ui.searchText = ""
    },
    setItemList: (state, payload) => {
        state.itemList = payload;
    },
    pushItemList: (state, payload) => {
        state.itemList.push(...payload);
    },
    setLoading: (state, pLoading) => {
        state.ui.loading = pLoading;
    },
    setLoadMore: (state, payload) => {
        state.ui.loadMore = payload;
    },
    setRowNum: (state, payload) => {
        state.ui.rowNum = payload;
    },
    setSearchText: (state, payload) => {
        state.ui.searchText = payload;
        // reset
        state.ui.rowNum = 1;
        state.ui.loadMore = false;
        state.itemList = [];
        state.catalogItem = {};
        state.ui.currentCategory = {}
    },
};

// GETTERS
const getters = {
    itemList: (state) => {
        return state.itemList;
    },
    catalogItem: (state) => {
        return state.catalogItem;
    },
    catalogItemSKU: (state) => {
        if (state.catalogItem.itemSKUs) return state.catalogItem.itemSKUs[0];
    },
    currentCategory: (state) => {
        return state.ui.currentCategory;
    },
    currentCategoryName: (state) => {
        return state.ui.currentCategory.name || "";
    },
    loading: (state) => {
        return state.ui.loading;
    },
    loadMore: (state) => {
        return state.ui.loadMore;
    },
    categoryList: (state) => {
        return state.categoryList;
    },
    searchText: (state) => {
        return state.ui.searchText;
    },
    rowNum: (state) => {
        return state.ui.rowNum;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
