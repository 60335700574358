import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

import nl from "vuetify/src/locale/nl.ts";
import en from "vuetify/src/locale/en.ts";
import fr from "vuetify/src/locale/fr.ts";

const LedTheme = {

    primary: '#283c5f',  // deep, dark blue van Logo LedTechno 
    secondary: '#041d40',  // slightly lighter blue for headers, buttons, etc.
    accent: '#f6d280',      // bright yellow for calls to action, highlights, etc.
    error: '#c02626',       // red for errors and warnings
    info: '#007bff',        // blue for informational messages
    success: '#28a745',      // green for success messages
    warning: '#ffc107',      // yellow for warnings
    background: '#f2f5f7',   // light gray for the background
    surface: '#ffffff'       // white for surfaces and cards
}

export default new Vuetify({
    lang: {
        locales: { nl, en, fr },
        current: "nl",
    },
    theme: {
        themes: {
            light: LedTheme
        }
    }
});
