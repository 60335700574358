import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

const numberFormats = {
    "nl": {
        currency: {
            style: 'currency', currency: 'EUR', notation: 'standard'
        },
        decimal: {
            style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 5
        },
        percent: {
            style: 'percent', useGrouping: false
        }
    },
    "fr": {
        currency: {
            style: 'currency', currency: 'EUR', notation: 'standard'
        },
        decimal: {
            style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 5
        },
        percent: {
            style: 'percent', useGrouping: false
        }
    },
    "en": {
        currency: {
            style: 'currency', currency: 'EUR', notation: 'standard'
        },
        decimal: {
            style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 5
        },
        percent: {
            style: 'percent', useGrouping: false
        }
    },
};

const dateTimeFormats = {
    "nl": {
        short: {
            year: "numeric",
            month: "short",
            day: "numeric",
        },
        long: {
            year: "numeric",
            month: "long",
            day: "numeric",
            weekday: "long",
            hour: "numeric",
            minute: "numeric",
        },
    },
    "en": {
        short: {
            year: "numeric",
            month: "short",
            day: "numeric",
        },
        long: {
            year: "numeric",
            month: "long",
            day: "numeric",
            weekday: "long",
            hour: "numeric",
            minute: "numeric",
        },
    },
    "fr": {
        short: {
            year: "numeric",
            month: "short",
            day: "numeric",
        },
        long: {
            year: "numeric",
            month: "long",
            day: "numeric",
            weekday: "long",
            hour: "numeric",
            minute: "numeric",
        },
    },
};

function loadLocaleMessages() {
    const locales = require.context(
        "@/locales",
        true,
        /[A-Za-z0-9-_,\s]+\.json$/i
    );
    const messages = {};
    locales.keys().forEach((key) => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i);
        if (matched && matched.length > 1) {
            const locale = matched[1];
            messages[locale] = locales(key);
        }
    });
    return messages;
}

export default new VueI18n({
    numberFormats,
    dateTimeFormats,
    locale: navigator.language,
    fallbackLocale: 'en',
    messages: loadLocaleMessages(),
});
