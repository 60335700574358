<template>
	<v-app id="app">
		<!-- NAVDRAWER  -->
		<v-navigation-drawer v-if="sessionActive" app floating v-model="drawer" color="white" disable-resize-watcher>
			<v-card flat>
				<v-card-title>
					<v-btn plain :to="{ name: 'Home' }">
						<v-img src="@/assets/logo.svg" height="32" contain position="left center" />
					</v-btn>
				</v-card-title>
				<v-card-text>
					<smart-nav-bar vertical />
					<v-list nav flat>
						<v-list-item router :to="{ name: 'ShoppingCart' }">
							<v-list-item-title>{{
								$t("App.Check_out")
							}}</v-list-item-title>
						</v-list-item>
						<v-list-item router :to="{ name: 'UserProfile' }">
							<v-list-item-title>{{
								$t("App.My_profile")
							}}</v-list-item-title>
						</v-list-item>
						<v-divider />
						<v-list-item router :to="{ name: 'Logout' }">
							<v-list-item-icon>
								<v-icon>mdi-exit-to-app</v-icon>
							</v-list-item-icon>
							<v-list-item-title>{{
								$t("App.Sign_out")
							}}</v-list-item-title>
						</v-list-item>
					</v-list>
				</v-card-text>
			</v-card>
		</v-navigation-drawer>

		<!-- APP BAR -->
		<v-app-bar app v-if="sessionActive" flat v-resize="onResize" v-scroll="onScroll" height="80" class="background">
			<v-container fluid><v-row align="center">
					<v-app-bar-nav-icon v-if="!horizontal" @click="drawer = !drawer"></v-app-bar-nav-icon>

					<v-toolbar-title>
						<v-btn plain :to="{ name: 'Home' }">
							<v-img src="@/assets/logo.svg" height="36" contain position="left center" />
						</v-btn>
					</v-toolbar-title>

					<smart-nav-bar v-if="horizontal" class="kmp" />

					<v-spacer />

					<v-badge v-if="cartItemCount && horizontal" color="info" :content="cartItemCount" overlap
						:value="cartItemCount">
						<v-btn icon :to="{ name: 'ShoppingCart' }"><v-icon>mdi-cart-outline</v-icon></v-btn>
					</v-badge>

					<v-btn v-if="horizontal" fab large icon class="ml-6 mr-3 my-auto" :to="{ name: 'UserProfile' }">
						<v-icon>mdi-account-outline</v-icon>
					</v-btn>

					<v-btn v-if="horizontal" fab icon class="my-auto" :to="{ name: 'Logout' }">
						<v-icon>mdi-exit-to-app</v-icon></v-btn>
				</v-row>
			</v-container>
		</v-app-bar>

		<!-- MAIN -->
		<v-main v-resize="onResize" v-scroll="onScroll" class="background fill-height">
			<transition name="fade" mode="out-in">

				<router-view />

			</transition>
		</v-main>

		<!-- SCROLL TOP -->
		<v-btn class="mb-4" v-if="sessionActive && this.scroller" style="position: fixed; bottom: 0; right: 0" large
			color="info" icon v-on:click.stop="$vuetify.goTo(0)">
			<v-icon>mdi-arrow-up-thin-circle-outline</v-icon>
		</v-btn>

		<!-- BOTTOM SHEET -->
		<v-bottom-sheet v-model="bottomSheet.isVisible" hide-overlay scrollable width="auto">
			<v-sheet v-if="bottomSheet.success"><v-btn icon large @click="bottomSheet.isVisible = false"><v-icon
						color="success">mdi-thumb-up</v-icon></v-btn></v-sheet>
			<v-card v-else class="mx-auto" max-height="150px" dark>
				<v-card-title>
					<v-spacer />
					<v-btn right icon @click="bottomSheet.isVisible = false"><v-icon>mdi-close-circle-outline</v-icon>
					</v-btn>
				</v-card-title>
				<v-card-text class="text-center">
					{{ bottomSheet.message }}
				</v-card-text>
			</v-card>
		</v-bottom-sheet>
	</v-app>
</template>

<script>
import lodash from "lodash";
import { mapGetters } from "vuex";
import SmartNavBar from "./components/SmartNavBar.vue";

export default {
	name: "App",
	components: { SmartNavBar },
	data: () => ({
		drawer: false,
		scroller: false,
		tenantName: process.env.VUE_APP_TENANT_NAME,
		appName: process.env.VUE_APP_APP_NAME,
	}),
	watch: {
		'$root.$i18n.locale': {
			handler(newValue) {
				// update all stores
				this.$store.commit('session/setLocale', newValue)
				this.$store.commit('catalog/setLocale', newValue)
				this.$store.commit('shoppingCart/setLocale', newValue)
				// update vuetify
				this.$vuetify.lang.current = newValue
			},
			immediate: true,
		},
	},
	computed: {
		...mapGetters({
			sessionActive: "session/sessionActive",
			userName: "session/userName",
			bottomSheet: "session/bottomSheet",
			cartItemCount: "shoppingCart/cartItemCount",
		}),
		horizontal() {
			return this.$vuetify.breakpoint.mdAndUp;
		},
	},
	methods: {
		onResize() {
			let fx = lodash.debounce(this.setContentHeight, 1000);
			fx();
		},
		onScroll() {
			this.scroller = window.pageYOffset > 256;
		},
		setContentHeight() {
			this.$store.dispatch("session/updateUI", {
				contentHeight:
					window.innerHeight -
					this.$vuetify.application.top -
					this.$vuetify.application.footer,
			});
		},
	},
};
</script>

<style>
.sticky-top {
	position: sticky;
	top: 80px;
	z-index: 3;
}

/*** SCROLLBAR ***/
/* width */
::-webkit-scrollbar {
	width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
	background: #eceff1;
	/* blue-grey lighten-5  */
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #b0bec5;
	/* blue-blue-grey lighten-5  */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #78909c;
	/* blue-grey lighten-1 */
}

/*** TRANSITIONS ***/
.fade-enter {
	opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.5s ease-out;
}

.fade-leave-to {
	opacity: 0;
}

.slide-fade-enter {
	transform: translateX(10px);
	opacity: 0;
}

.slide-fade-enter-active,
.slide-fade-leave-active {
	transition: all 0.25s ease;
}

.slide-fade-leave-to {
	transform: translateX(-10px);
	opacity: 0;
}

.kmp {
	position: fixed;
	left: 50%;
	transform: translate(-50%, 0%);
}

.grad {
	background-image: radial-gradient(circle,
			#e3f2fd,
			#dbeefc,
			#d4ebfc,
			#cce7fb,
			#c5e3fb,
			#bee0fb,
			#b8dcfa,
			#b1d9fa,
			#a9d5fa,
			#a1d2f9,
			#98cef9,
			#90caf9) !important;
}</style>
