import Vue from "vue";
import App from "@/App.vue";
import router from "@/router/router";
import store from "@/store";
import vuetify from "@/plugins/vuetify";
import { fireAuth } from "@/services/firebase";
import i18n from "@/plugins/i18n";

Vue.config.productionTip = false;

/* wait for firebase to determine the auth state */
let app;
fireAuth.onAuthStateChanged((user) => {
    store.commit("session/setFirebaseUser", null);
    if (user) {
        store.commit("session/setFirebaseUser", user);
    }
    // now mount the vue app (once)
    if (!app) {
        app = new Vue({
            router,
            store,
            vuetify,
            i18n,
            render: (h) => h(App),
        }).$mount("#app");
    }
});

